<template>
  <div class="audio-uploading">
    <h3 class="modal__title">Please wait for all files to upload</h3>
    <div class="modal__body">
      <div class="audio-uploading__wrapper">
        <div class="audio-uploading__list">
          <div
            class="audio-uploading__item"
            v-for="item in list"
            :key="item.key"
          >
            <div class="audio-uploading__title">{{ item.title }}</div>
            <div
              class="audio-uploading__status"
              :class="{ completed: item.completed, error: item.error }"
            >
              <span v-if="item.completed">Complete</span>
              <span v-else-if="item.error">Error</span>
              <span v-else>Uploading...</span>
            </div>
          </div>
        </div>
        <div class="audio-uploading__loader">
          <loader :container="true" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import Loader from "@/components/loader";
import { RECORD_FILE_DATA } from "@/constants";
import { getStorage } from "@/modules/localStorage";

export default {
  components: { Loader },

  computed: {
    ...mapState(["uploading", "user"]),

    list() {
      console.log("in list for uploading pop up")
      console.log(getStorage("user"))
      const get_feeling_today = getStorage("user").get_feeling_today;
      return Object.entries(RECORD_FILE_DATA).map(([key, { title }]) => ({
        key,
        title,
        completed: this.uploading[key]?.uploaded,
        loading: this.uploading[key]?.loading,
        error: this.uploading[key]?.error,
      })).filter(
        function(obj) {
          console.log(obj)
          console.log(obj.key)
          // console.log(RECORD_FILE_DATA.audio7.key)
          console.log(obj.key == "audio7")
          console.log(!(!get_feeling_today && obj.key == "audio7"))
          return !(!get_feeling_today && obj.key == "audio7")
          // console.log(!this.$store.state.user.get_feeling_today && obj.key == RECORD_FILE_DATA.audio7.key)
          // console.log(!this.user.get_feeling_today && obj.key == RECORD_FILE_DATA.audio7.key)
          // return !(!this.$store.state.user.get_feeling_today && obj.key == RECORD_FILE_DATA.audio7.key)
          // return !(!this.user.get_feeling_today && obj.key == RECORD_FILE_DATA.audio7.key)
        }
      );
    },
  },

  methods: {
    mounted() {
      console.log("mounted uploading popup component")
      console.log(getStorage("user"))
      console.log(this.$store.state.user)
      console.log(this.user)
    }
  } 
};
</script>
